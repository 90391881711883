<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="CalendarIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Delivery Date</h3>
        <div class="w-75">
          <b-form-group>
            <p
              type="text"
              class="form-control mr-2 mt-1 d-flex align-items-center"
            >
              {{ form.delivery_datetime }}
            </p>
          </b-form-group>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="TruckIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Origin Point</h3>
        <div class="w-75">
          <p
            type="text"
            class="form-control mr-2 mt-1 d-flex align-items-center"
          >
            {{ form.origin_point.address }}
          </p>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="HomeIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Sender's Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_first_name }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_last_name }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_phone_number }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_unit_number }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_1 }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_2 }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_postcode }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_area }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_state }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_country }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="HomeIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Recipient's Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_first_name }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_last_name }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_phone_number }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_unit_number }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_address_line_1 }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_address_line_2 }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_postcode }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_area }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.recipient_state }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.recipient_country }}
            </p>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="BoxIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Product Details</h3>
        <div class="w-75">
          <vue-good-table
            :columns="incoming.columns"
            :rows="incoming.rows"
            style-class="vgt-table"
          />
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center mt-3">
      <b-button
        v-b-modal.modal-center
        variant="light"
        class="box-shadow-1 px-5 mr-3 w-25"
        @click="onBack"
        >Back</b-button
      >
      <b-button
        v-b-modal.modal-center
        variant="gradient-primary"
        class="box-shadow-1 px-5 w-25"
        @click="onSubmit"
        >Confirm Delivery</b-button
      >
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { VueGoodTable } from 'vue-good-table';
import axios from '@axios';

export default {
  components: {
    flatPickr,
    VueGoodTable,
  },
  data() {
    return {
      form: {},
      double_charge: '',
      incoming: {
        columns: [
          {
            label: 'Product Category',
            field: 'product_category',
          },
          {
            label: 'Packaging',
            field: 'packaging',
          },
          {
            label: 'QTY',
            field: 'quantity',
          },
          {
            label: 'Size',
            field: 'item_size',
          },
          {
            label: 'Weight ( kg )',
            field: 'item_weight',
          },
          {
            label: 'SKU',
            field: 'sku',
          },
          {
            label: 'Temperature',
            field: 'temperature',
          },
          {
            label: 'Remarks',
            field: 'remarks',
          },
        ],
        rows: [{}],
      },
      address: [],
      addressName: '',
      form2: {},
    };
  },
  created() {
    this.form = this.$route.params.data;
    this.double_charge = this.$route.params.double_charge;
    this.incoming.rows[0].product_category = this.form.product_category;
    this.incoming.rows[0].sku = this.form.sku;
    this.incoming.rows[0].packaging = this.form.packaging;
    this.incoming.rows[0].quantity = this.form.quantity;
    this.incoming.rows[0].temperature = this.form.temperature;
    this.incoming.rows[0].remarks = this.form.remarks;
    this.incoming.rows[0].item_size = this.form.item_size;
    this.incoming.rows[0].item_weight = this.form.item_weight;
  },
  mounted() {
    axios;
    this.$http
      .get('branch')
      .then((response) => (this.address = response.data.data));
  },
  methods: {
    onSubmit() {
      this.$router.push({
        name: 'Outlet-deliverycart',
        params: { data: this.form, double_charge: this.double_charge },
      });
    },
    onBack() {
      this.$router.push({
        name: 'Outlet-order',
        params: { data: this.form, double_charge: this.double_charge },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.buttonDHL {
  width: 10rem;
  background-color: #29cc97;
}
</style>
